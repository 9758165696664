import React from 'react';
import { useEffect, useState } from 'react';


import PropTypes from 'prop-types';

/**
 * countdown component
 * This is the countdown component for CPO Portal.
 * @author Michael Deumlich <m.deumlich@enport.de>
 * @component
 * @example
 *  <CountDown />
 */

const useCountDown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime(),
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};





const CountDown = (props) => {

    const { targetDate } = props;

    const [days, hours, minutes, seconds] = useCountDown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <span className="expired-notice">
            <span>Expired!!!</span>
        </span>;
    } else {
        return (
            <span className="show-counter">
                <span className="countdown">
                    <span>{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</span>

                </span>
            </span>
        );
    }
};

CountDown.propTypes = {
    targetDate: PropTypes.number,
   
};
CountDown.defaultProps = {
    targetDate: '',
};

export default CountDown;