import React from 'react';

/**
 * Loading component
 * This is a common component to be used across the project.
 * Loading component to display the loading indicator on the all pages for CPO Portal
 * @author Mitul Kirti<mitul.kirti@hcl.com>
 * @component
 * @example
 *  <LoadingComponent />
 */
const LoadingComponent = () => {

    return (
        <div className="cpo-preload" data-testid="loading-container">
            <eon-ui-preloader-circle />
        </div>
    );
};

export default LoadingComponent;
