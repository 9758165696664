
import React from 'react';
import Layout from './components/layout/layout';
import LayoutGuest from './components/layout/layoutGuest';
import Login from './components/login-auth/loginAuth';
import Dashboard from './components/dashboard/dashboard';

import Error from './components/errors/error';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthProvider } from './common-utility/authcontext';

function App() {


    return (
        <>
            <eon-ui-page-wrapper>

                <BrowserRouter>
                    <AuthProvider>
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route element={<Layout />}>
                                <Route path="/dashboard" element={<Dashboard />} />
                            </Route>

                            <Route element={<LayoutGuest />}>
                                <Route path="/error_api" element={<Error type="api" />} />
                                <Route path="/error_expired" element={<Error type="expired" />} />
                                <Route path="*" element={<Error type="notfound" />} />
                            </Route>
                        </Routes>
                    </AuthProvider>
                </BrowserRouter>

            </eon-ui-page-wrapper>
        </>
    );



}

export default App;
