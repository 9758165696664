import React, { useRef, useEffect, useContext } from 'react';

import { AuthContext } from '../../common-utility/authcontext';
import './navbar.scss';

const Navbarguest = () => {

    const { logout } = useContext(AuthContext);

    const isMounted = useRef(false);

    useEffect(() => {

        if (!isMounted.current) {
            document.getElementById('navbarLogoutBtn').addEventListener('navigationMainIconLinkClick', () => {

                console.log('Logout btn clicked');
                logout();

                console.log('User has logged out!', document.cookie);
            });


            isMounted.current = true;
        }
        return () => {
            isMounted.current = false;
        };
    }, [document.location.href]);

    return (
        <>
            <div className="color-separator" data-testid="color-separator">
                <eon-ui-color-separator />
            </div>
            <div className='wrapper' data-testid="wrapper">
                <eon-ui-navigation sticky="false">
                    <eon-ui-navigation-main slot="main">
                        <eon-ui-navigation-column content-alignment="right" content-alignment-mobile="left" grow="false" grow-mobile="false">
                            <eon-ui-navigation-main-icon-link id="navbarLogoutBtn" icon="logout" href={`${window.env.CIAM_LOGOUT_URL}`} />
                            <eon-ui-navigation-logo image-src="/EON_Drive_Logo.svg" description="E.ON Logo" href='/' />
                        </eon-ui-navigation-column>
                    </eon-ui-navigation-main>
                </eon-ui-navigation>
            </div>
        </>

    );
};

export default Navbarguest;