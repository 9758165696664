import React, { useRef, useEffect, useState } from 'react';
import './datatable.scss';
import constants from '../../constants';
import LoadingComponent from '../cpo-loading/cpoLoading';
import ModalComponent from '../cpo-modals/modalComponent';
import SingleBtnModalComponent from '../cpo-modals/singleBtnModalComponent';

import AlertMessage from '../cpo-message/alertMessage';


import { getCookie } from '../../common-utility/index';

/**
 * Datatable component
 * This component is used to display the table on the dashboard
 * @author Mitul Kirti <mitul.kirti@hcl.com>
 * @component
 * @example
 *  <Datatable />
 */

// let statusFilterVal = '';
let updateSelectedCP = [];

let messageText = '';
let messageDetailedText = '';
let messageType = 'alert';

const Datatable = () => {
    const statusColors = {
        UPTODATE: constants.STATUS_COLORS.UPTODATE,
        AVAILABLE: constants.STATUS_COLORS.AVAILABLE,
        FAILED: constants.STATUS_COLORS.FAILED,
        REQUESTED: constants.STATUS_COLORS.REQUESTED,
    };
    const displayStatus = {
        UPTODATE: 'datatable.filter.status.items.UPDATED'.translate(),
        AVAILABLE: 'datatable.filter.status.items.AVAILABLE'.translate(),
        FAILED: 'datatable.filter.status.items.FAILED'.translate(),
        REQUESTED: 'datatable.filter.status.items.REQUESTED'.translate(),
        SECURITY_ALERT: 'datatable.filter.status.items.SECURITY_ALERT'.translate(),
        SECURITY_ALERT_HARDWARE: 'datatable.filter.status.items.SECURITY_ALERT_HARDWARE'.translate(),
        SECURITY_ALERT_SOFTWARE: 'datatable.filter.status.items.SECURITY_ALERT_SOFTWARE'.translate(),
    };
    const statusBackend = {
        UPTODATE: constants.STATUS_BACKEND.UPTODATE,
        AVAILABLE: constants.STATUS_BACKEND.AVAILABLE,
        FAILED: constants.STATUS_BACKEND.FAILED,
        REQUESTED: constants.STATUS_BACKEND.REQUESTED,
        SECURITY_ALERT: constants.STATUS_BACKEND.SECURITY_ALERT,
        SECURITY_ALERT_HARDWARE: constants.STATUS_BACKEND.SECURITY_ALERT_HARDWARE,
        SECURITY_ALERT_SOFTWARE: constants.STATUS_BACKEND.SECURITY_ALERT_SOFTWARE
    };

    const isMounted = useRef(false);
    const chargePointsData = useRef();
    const autoUpdateOn = useRef(false);
    const [firmwareDetailsTable, setFirmwareDetailsTable] = useState([]);
    const [loader, setLoader] = useState(false);
    const [statusFilterActive, setStatusFilterActive] = useState(false);
    // const [autoUpdateOn, setAutoUpdateOn] = useState(false);
    const [messageIsVisible, setMessageIsVisible] = useState(false);
    const selectedevseID = useRef();

    console.log('Datatable');

    let firmwareUpdateTable = document.getElementById('firmware-details-table');
    if (firmwareUpdateTable && !firmwareUpdateTable.getAttribute('listener')) {
        firmwareUpdateTable.setAttribute('listener', 'true');
        console.log('setAttribute selectionChanged');
        firmwareUpdateTable.addEventListener('selectionChanged', (e) => {
            console.log('selectionChanged', e);
            updateSelectedFirmwares(e.detail);
        });
    }

    let statusFilterCM = document.getElementById('status-filter');
    console.log('statusFilterCM', statusFilterCM);
    if (statusFilterCM && !statusFilterCM.getAttribute('listener')) {
        statusFilterCM.setAttribute('listener', 'true');
        console.log('setAttribute contextMenuItemOnSelect');
        statusFilterCM.addEventListener('contextMenuItemOnSelect', (e) => {
            console.log('contextMenuItemOnSelect', e);
            statusFilterValue(e.detail.value);
        });
    }

    useEffect(() => {
        console.log('Datatable useEffect');
        setLoader(true);

        console.log('Datatable isMounted', isMounted);

        if (!isMounted.current) {

            // load Chargepoints and then show alert messages
            fetchChargePoints().then(() => {

                checkChargePointsForSecurityNotification();

            });

            console.log('Datatable !isMounted.current');


            {/* confirm button clicks in modal for toolbar */ }
            let messageComponent = document.getElementById('alert_message');
            console.log('messageComponent', messageComponent);
            if (messageComponent && !messageComponent.getAttribute('listener')) {
                messageComponent.setAttribute('listener', 'true');
                messageComponent.addEventListener('messageClose', () => {
                    setMessageIsVisible(false);
                });
            }


            {/* confirm button clicks in modal for toolbar */ }
            const confirm_update_selected_btn = document.getElementById('confirm-update-selected-btn');
            if (confirm_update_selected_btn) {
                confirm_update_selected_btn.addEventListener('buttonClick', (e) => {
                    if (e.type === 'buttonClick') {
                        handleConfirmClick('update-selected-modal', 'updateFirmwareSelected');
                    }
                });
            }


            let modalConfirmAllBtnEL = document.getElementById('confirm-update-all-btn');
            if (modalConfirmAllBtnEL && !modalConfirmAllBtnEL.getAttribute('listener')) {
                modalConfirmAllBtnEL.setAttribute('listener', 'true');
                modalConfirmAllBtnEL.addEventListener('buttonClick', (e) => {
                    if (e.type === 'buttonClick') {
                        handleConfirmClick('update-all-modal', 'updateFirmwareAll');
                    }
                });
            }

            let confirm_auto_update_btn = document.getElementById('confirm-auto-update-btn');
            if (confirm_auto_update_btn) {
                confirm_auto_update_btn.addEventListener('buttonClick', (e) => {
                    if (e.type === 'buttonClick') {
                        handleConfirmClick('auto-update-modal', 'enableAutoUpdate');
                    }
                });
            }



            {/* confirm button clicks in modal for toolbar */ }

            let confirm_security_alert_row_btn = document.getElementById('confirm-security-alert-btn');
            if (confirm_security_alert_row_btn) {
                confirm_security_alert_row_btn.addEventListener('buttonClick', (e) => {
                    if (e.type === 'buttonClick') {
                        handleConfirmClick('security-alert', 'confirmSecurityAlert');
                    }
                });
            }

            let confirm_security_alert_hardware_row_btn = document.getElementById('confirm-security-alert-hardware-btn');
            if (confirm_security_alert_hardware_row_btn) {
                confirm_security_alert_hardware_row_btn.addEventListener('buttonClick', (e) => {
                    if (e.type === 'buttonClick') {
                        handleConfirmClick('security-alert-hardware', 'confirmSecurityAlert');
                    }
                });
            }

            let confirm_security_alert_software_row_btn = document.getElementById('confirm-security-alert-software-btn');
            if (confirm_security_alert_software_row_btn) {
                confirm_security_alert_software_row_btn.addEventListener('buttonClick', (e) => {
                    if (e.type === 'buttonClick') {
                        handleConfirmClick('security-alert-software', 'confirmSecurityAlert');
                    }
                });
            }

            {/* confirm button clicks in modal for toolbar */ }

            let confirm_update_row_btn = document.getElementById('confirm-update-firmare-alert-btn');
            if (confirm_update_row_btn) {
                confirm_update_row_btn.addEventListener('buttonClick', (e) => {
                    if (e.type === 'buttonClick') {
                        handleConfirmClick('update-firmware-alert', 'updateFirmwareOne');
                    }
                });
            }

            {/* confirm button clicks in modal for toolbar */ }

            let confirm_update_failed_row_btn = document.getElementById('confirm-update-failed-alert-btn');
            if (confirm_update_failed_row_btn) {
                confirm_update_failed_row_btn.addEventListener('buttonClick', (e) => {
                    if (e.type === 'buttonClick') {
                        handleConfirmClick('update-failed-alert', 'updateFirmwareOne');
                    }
                });
            }



            isMounted.current = true;
        }
        return () => {
            isMounted.current = false;
        };
    }, []);


    const checkChargePointsForSecurityNotification = () => {
        // search for status
        if (chargePointsData.current && chargePointsData.current.length > 0) {
            console.log('chargePointsData.current', chargePointsData.current);

            let securityAlertChargePoints = [];
            let securityAlertChargePoints_hardware = [];
            let securityAlertChargePoints_software = [];

            chargePointsData.current.forEach(currentCPDetails => {
                let tempSecurity = [];
                // determine if there is a node of securityEventNotification where the status != CONFIRMED, 
                // and show that there has been a security alert

                console.log('securityAlertChargePoints', currentCPDetails.securityEventNotification);
                if (currentCPDetails.securityEventNotification && currentCPDetails.securityEventNotification.length > 0) {
                    tempSecurity = currentCPDetails.securityEventNotification.filter(securityEventNotificationItem => {
                        return securityEventNotificationItem.status !== 'CONFIRMED';
                    });
                }

                if (tempSecurity.length > 0) {
                    // overwrite status with security alert
                    console.log('tempSecurity', tempSecurity);
                    switch (tempSecurity[0].type) {
                        case 'TamperDetectionActivated':
                            currentCPDetails.status = statusBackend.SECURITY_ALERT_HARDWARE;
                            securityAlertChargePoints_hardware.push(currentCPDetails);
                            break;
                        case 'InvalidFirmwareSignature':
                            currentCPDetails.status = statusBackend.SECURITY_ALERT_SOFTWARE;
                            securityAlertChargePoints_software.push(currentCPDetails);
                            break;
                        default:
                            currentCPDetails.status = statusBackend.SECURITY_ALERT;
                            securityAlertChargePoints.push(currentCPDetails);
                            break;
                    }
                    return true;
                } else {
                    return false;
                }
                // return currentCPDetails.status === statusBackend.SECURITY_ALERT;
            });

            let failedChargePoints = chargePointsData.current.filter(currentCPDetails => {
                return currentCPDetails.status === statusBackend.FAILED;
            });

            let availableChargePoints = chargePointsData.current.filter(currentCPDetails => {
                return currentCPDetails.status === statusBackend.AVAILABLE;
            });

            // show prioritized alert messages
            switch (true) {
                case securityAlertChargePoints.length > 0:
                    showMessage('datatable.alert.securityAlertChargePoints.title'.translate(), 'datatable.alert.securityAlertChargePoints.description'.translate(), 'alert');
                    break;
                case securityAlertChargePoints_hardware.length > 0:
                    showMessage('datatable.alert.securityAlertHardwareChargePoints.title'.translate(), 'datatable.alert.securityAlertHardwareChargePoints.description'.translate(), 'alert');
                    break;
                case securityAlertChargePoints_software.length > 0:
                    showMessage('datatable.alert.securityAlertSoftwareChargePoints.title'.translate(), 'datatable.alert.securityAlertSoftwareChargePoints.description'.translate(), 'alert');
                    break;
                case failedChargePoints.length > 0:
                    showMessage('datatable.alert.failedChargePoints.title'.translate(), 'datatable.alert.failedChargePoints.description'.translate(), 'alert');
                    break;
                case availableChargePoints.length > 0:
                    showMessage('datatable.alert.availableChargePoints.title'.translate(), 'datatable.alert.availableChargePoints.description'.translate(), 'alert');
                    break;
                case false:
                    showMessage('datatable.alert.successChargePoints.title'.translate(), 'datatable.alert.successChargePoints.description'.translate(), 'success');
                    break;
            }
        }
    };

    const fetchChargePoints = async () => {
        console.log('Datatable fetchChargePoints');
        setFirmwareDetailsTable([]);
        try {
            setLoader(true);


            const test_data = [
                {
                    'id': 104,
                    'chargeBoxId': 'CHARGEBOX1329',
                    'name': 'Testmock1',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [{
                        'type': 'TamperDetectionActivated',
                        'timestamp': '2023-06-17T16:00:47.560+02:00',
                        'techInfo': 'string',
                        'status': 'RECEIVED', // CONFIRMED
                    }, {
                        'type': 'TamperDetectionActivated',
                        'timestamp': '2023-06-20T16:00:47.560+02:00',
                        'techInfo': 'TamperDetectionActivated',
                        'status': 'CONFIRMED',
                    }, {
                        'type': 'TamperDetectionActivated',
                        'timestamp': '2023-06-21T16:00:47.560+02:00',
                        'techInfo': 'TamperDetectionActivated',
                        'status': 'CONFIRMED',
                    }],

                },
                {
                    'id': 107,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'Testmock2',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [{
                        'type': 'InvalidFirmwareSignature',
                        'timestamp': '2023-06-17T16:00:47.560+02:00',
                        'techInfo': 'string',
                        'status': 'RECEIVED',
                    }, {
                        'type': 'TamperDetectionActivated',
                        'timestamp': '2023-06-20T16:00:47.560+02:00',
                        'techInfo': 'TamperDetectionActivated',
                        'status': 'CONFIRMED',
                    }, {
                        'type': 'TamperDetectionActivated',
                        'timestamp': '2023-06-21T16:00:47.560+02:00',
                        'techInfo': 'TamperDetectionActivated',
                        'status': 'CONFIRMED',
                    }],

                },
                {
                    'id': 1071,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'Testmock3',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'SECURITY_ALERT',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 1072,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'FAILED',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 1073,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'REQUESTED',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 1074,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'AVAILABLE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 1075,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 1076,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 1077,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 1078,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 1079,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 10710,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 10711,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 10712,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
                {
                    'id': 10713,
                    'chargeBoxId': 't53_it1_3017_002',
                    'name': 'evse_name',
                    'model': 'modelv1',
                    'manufacturer': 'vendorv1',
                    'currentVersion': 'currentversion2',
                    'availableVersion': 'currentversion2',
                    'latestVersionDescription': '',
                    'status': 'UPTODATE',
                    'updatedAt': '2021-08-31T11:05:52+02:00',
                    'securityEventNotification': [

                    ],
                },
            ];

            setFirmwareDetailsTable(test_data);
            chargePointsData.current = test_data;
            setLoader(false);
            if (firmwareUpdateTable) {
                firmwareUpdateTable.setTableState({});
            }

            /*

           const headerList = {
               method: 'GET',
               headers: new Headers({
                   Authorization: `Bearer ${getCookie('token')}`,
                   'Cache-Control': 'no-cache',
               }),
           };

           await fetch(`${window.env.MIDDLEWARE_URL}/charger-portal/firmware/ciam-id`, headerList)
               .then(async (response) => {
                   let data = await response.json();
                   console.log('data inside update selected', data);
                   if (response.status === 200) {
                       setFirmwareDetailsTable(data);
                       chargePointsData.current = data;
                       setLoader(false);
                       if (firmwareUpdateTable) {
                           firmwareUpdateTable.setTableState({});
                       }
                   } else {
                       // Rest of status codes (400,500,303), can be handled here appropriately
                       showMessage('datatable.alert.errorApi.title'.translate(), 'datatable.alert.errorApi.description'.translate(), 'alert');
                       setFirmwareDetailsTable([]);
                       chargePointsData.current = [];
                       setLoader(false);
                       if (firmwareUpdateTable) {
                           firmwareUpdateTable.setTableState({});
                       }
                   }
               });
               */

        }
        catch (err) {
            console.log('Error fetching data' + err);
            showMessage('datatable.alert.errorApi.title'.translate(), 'datatable.alert.errorApi.description'.translate(), 'alert');
            setLoader(false);
        }
    };

    /**
    * Function called when user confirms the action of Update firmware button using the status column tab pill.
   */
    const enableAutoUpdate = async () => {

        // get opposite of autoUpdate
        const _autoUpdateOn = !autoUpdateOn.current;
        // generate data
        const chargePointsToEnableAutoUpdate = chargePointsData.current.map(item => { return { 'evseId': item.id, 'autoUpdate': _autoUpdateOn }; });

        console.log(chargePointsToEnableAutoUpdate.length);
        if (chargePointsToEnableAutoUpdate.length > 0) {

            try {
                setLoader(true);
                console.log('enableAutoUpdate');

                const requestOptions = {
                    method: 'PUT',
                    // headers: { 'Content-Type': 'application/json' },
                    headers: new Headers({
                        Authorization: `Bearer ${getCookie('token')}`,
                        'Content-Type': 'application/json',
                    }),
                    body: JSON.stringify(chargePointsToEnableAutoUpdate),
                };

                await fetch(`${window.env.MIDDLEWARE_URL}/charger-portal/firmware/enable-auto-update`, requestOptions)
                    .then(async (response) => {
                        if (response.status === 200) {
                            // Process data here
                            showMessage('datatable.alert.successChargePointsSubmitted.title'.translate(), 'datatable.alert.successChargePointsSubmitted.description'.translate(), 'success');

                            // only if enabling was successful set auto update state
                            autoUpdateOn.current = _autoUpdateOn;
                        } else {
                            // Rest of status codes (400,500,303), can be handled here appropriately
                            showMessage('datatable.alert.errorApi.title'.translate(), 'datatable.alert.errorApi.description'.translate(), 'alert');


                        }
                        setLoader(false);
                    });
            }
            catch (err) {
                showMessage('datatable.alert.errorApi.title'.translate(), 'datatable.alert.errorApi.description'.translate(), 'alert');
                console.log('Error fetching data' + err);

                setLoader(false);
            }
        } else {
            showMessage('datatable.alert.errorNoChargepoints.title'.translate(), 'datatable.alert.errorNoChargepoints.description'.translate(), 'warning');
        }


    };

    /**
  * Function called when user confirms the action of Update firmware button using the status column tab pill.
  */
    const updateFirmware = async (evseIds) => {
        console.log(evseIds.length);
        if (evseIds.length > 0) {

            try {
                setLoader(true);
                console.log('updateFirmware');

                const requestOptions = {
                    method: 'PUT',
                    // headers: { 'Content-Type': 'application/json' },
                    headers: new Headers({
                        Authorization: `Bearer ${getCookie('token')}`,
                        'Content-Type': 'application/json',
                    }),
                    body: JSON.stringify({ evseIds: evseIds }),
                };

                await fetch(`${window.env.MIDDLEWARE_URL}/charger-portal/firmware/update-firmware`, requestOptions)
                    .then(async (response) => {
                        if (response.status === 200) {
                            // Process data here
                            showMessage('datatable.alert.successChargePointsSubmitted.title'.translate(), 'datatable.alert.successChargePointsSubmitted.description'.translate(), 'success');

                            fetchChargePoints().then(() => {

                                checkChargePointsForSecurityNotification();

                            });
                        } else {
                            // Rest of status codes (400,500,303), can be handled here appropriately
                            showMessage('datatable.alert.errorApi.title'.translate(), 'datatable.alert.errorApi.description'.translate(), 'alert');

                            fetchChargePoints().then(() => {

                                checkChargePointsForSecurityNotification();

                            });
                        }
                        setLoader(false);
                    });
            }
            catch (err) {
                showMessage('datatable.alert.errorApi.title'.translate(), 'datatable.alert.errorApi.description'.translate(), 'alert');
                console.log('Error fetching data' + err);

                setLoader(false);
            }
        } else {
            showMessage('datatable.alert.errorNoChargepoints.title'.translate(), 'datatable.alert.errorNoChargepoints.description'.translate(), 'warning');
        }
    };

    /**
* Function called when user confirms a security alert
*/
    const confirmSecurityAlert = async (evseId) => {
        setLoader(true);
        try {
            setLoader(true);
            console.log('acknowledgeSecurity');

            const requestOptions = {
                method: 'PATCH',
                // headers: { 'Content-Type': 'application/json' },
                headers: new Headers({
                    Authorization: `Bearer ${getCookie('token')}`,
                    'Content-Type': 'application/json',
                }),
                // no body: JSON.stringify({ evseIds: evseId }),
            };

            console.log(requestOptions);

            await fetch(`${window.env.MIDDLEWARE_URL}/charger-portal/security/acknowledgement/` + evseId, requestOptions)
                .then(async (response) => {

                    if (response.ok) {
                        // Process data here
                        showMessage('datatable.alert.securityAlertChargePointsAcknowledgeSubmitted.title'.translate(), 'datatable.alert.securityAlertChargePointsAcknowledgeSubmitted.description'.translate(), 'success');

                        fetchChargePoints().then(() => {

                            checkChargePointsForSecurityNotification();

                        });
                    } else {
                        // Rest of status codes (400,500,303), can be handled here appropriately
                        showMessage('datatable.alert.errorApi.title'.translate(), 'datatable.alert.errorApi.description'.translate(), 'alert');

                        fetchChargePoints().then(() => {

                            checkChargePointsForSecurityNotification();

                        });
                    }
                    setLoader(false);
                });
        }
        catch (err) {
            console.log('Error fetching data' + err);
            showMessage('datatable.alert.errorApi.title'.translate(), 'datatable.alert.errorApi.description'.translate(), 'alert');
            setLoader(false);
        }
    };


    /**
    * Function to display modal on click of tab pills (status column buttons)
    * @param {string} evse_id id evse
    */
    const updateManually = async (evse_id) => {
        console.log('Update button clicked for evse id', evse_id, firmwareDetailsTable, statusBackend);
        selectedevseID.current = evse_id;


        // Find the charge point based on status to display the respective modal.
        let chargeBox = firmwareDetailsTable.find(firmwareDetailsTableItem => {
            return firmwareDetailsTableItem.id === evse_id;
        });


        console.log(chargeBox.status);
        switch (chargeBox.status) {
            case statusBackend.SECURITY_ALERT:
                showModal('security-alert');
                break;
            case statusBackend.SECURITY_ALERT_HARDWARE:
                showModal('security-alert-hardware');
                break;
            case statusBackend.SECURITY_ALERT_SOFTWARE:
                showModal('security-alert-software');
                break;
            case statusBackend.UPTODATE:
                showModal('up-to-date-alert');
                break;
            case statusBackend.AVAILABLE:
                showModal('update-firmware-alert');
                break;
            case statusBackend.FAILED:
                showModal('update-failed-alert');
                break;
            case statusBackend.REQUESTED:
                showModal('update-in-progress-alert');
                break;
        }

    };


    /**
    * Function to display modal
    * @param {string} modalId id of modal
    */
    const showModal = (modalId) => {
        const displayModal = document.querySelector(`[modal-id=${modalId}]`);
        displayModal.setAttribute('visible', 'true');
    };

    /**
    * Function to hide modal
    * @param {string} modalId id of modal
    */
    const hideModal = (modalId) => {
        const displayModal = document.querySelector(`[modal-id=${modalId}]`);
        displayModal.setAttribute('visible', 'false');
    };

    const handleConfirmClick = async (modalId, action) => {
        setMessageIsVisible(false);

        switch (action) {
            case 'updateFirmwareAll': {
                console.log('updateFirmwareAll');
                let updateAllChargePoints = chargePointsData.current.filter(updateAllCPDetails => {
                    return updateAllCPDetails.status !== statusBackend.UPTODATE && updateAllCPDetails.status !== statusBackend.SECURITY_ALERT && updateAllCPDetails.status !== statusBackend.REQUESTED;
                });

                let chargePointsToBeUpdated = updateAllChargePoints.map(item => { return item.id; });

                updateFirmware(chargePointsToBeUpdated);
                break;
            }
            case 'updateFirmwareSelected': {
                console.log('updateFirmwareSelected', updateSelectedCP, chargePointsData);
                // match ids to chargepoints
                let selectedChargePoints = chargePointsData.current.filter(x => updateSelectedCP.includes('' + x.id));
                console.log(selectedChargePoints);

                // match only status uptodate
                let updateChargePoints = selectedChargePoints.filter(updateAllCPDetails => {
                    return updateAllCPDetails.status !== statusBackend.UPTODATE && updateAllCPDetails.status !== statusBackend.SECURITY_ALERT && updateAllCPDetails.status !== statusBackend.REQUESTED;
                });

                // get ids
                let chargePointsToBeUpdated = updateChargePoints.map(item => { return item.id; });

                updateFirmware(chargePointsToBeUpdated);

            }
                break;
            case 'updateFirmwareOne':

                console.log('updateFirmwareOne');
                updateFirmware([selectedevseID.current]);
                break;

            case 'confirmSecurityAlert':

                console.log('confirmSecurityAlert');
                if (selectedevseID.current) {
                    confirmSecurityAlert(selectedevseID.current);
                }
                break;

            case 'enableAutoUpdate':

                console.log('enableAutoUpdate', autoUpdateOn);
                if (chargePointsData.current) {

                    enableAutoUpdate();
                }
                break;

        }


        hideModal(modalId);
    };

    /**
    * Function to handle Update Selected button on the toolbar which appears when user selects one or more
    * charge points using the checkbox provided.
    * @param {string} selectedFirmware 
    */
    const updateSelectedFirmwares = async (selectedFirmware) => {
        updateSelectedCP = selectedFirmware;
    };

    /*Function to handle status column filter-context menu options */
    const statusFilterValue = (statusFilterVal) => {



        // statusFilterVal = statusOptions;
        console.log('statusOptions', statusFilterVal);
        setStatusFilterActive(true);

        if (statusFilterVal !== '') {
            if (statusFilterVal === statusBackend.SECURITY_ALERT) {
                setFirmwareDetailsTable(chargePointsData.current.filter(filteredTableData => filteredTableData.status === statusBackend.SECURITY_ALERT ||
                    filteredTableData.status === statusBackend.SECURITY_ALERT_HARDWARE ||
                    filteredTableData.status === statusBackend.SECURITY_ALERT_SOFTWARE));
            } else {
                setFirmwareDetailsTable(chargePointsData.current.filter(filteredTableData => filteredTableData.status === statusFilterVal));
            }

        } else {
            setFirmwareDetailsTable(chargePointsData.current);
        }

        // fetchTableDataUsingCiamID();
        // setStatusFilterActive(false);
    };


    /**
    * Function to display message
    * @param {string} headline headline of message
    * @param {string} text text of message
    * @param {string} type type of message
    */
    const showMessage = (headline, text, type = 'alert') => {

        setMessageIsVisible(true);
        messageText = headline;
        messageDetailedText = text;
        messageType = type;
    };

    /*
        firmwareUpdateTable.setTableState({
            pageSize: this.pageSize,
            begin: (this.currentPage - 1) * this.pageSize,
            end: this.begin + this.pageSize > this.listLength ? this.listLength : this.begin + this.pageSize,
            totalItems: firmwareDetailsTable.length,
            currentPage: number,
            sortKey: string,
            sortOrder: SortOrder,
            searchTerm: string,
        }
        );
    */

    return (
        <>
            <AlertMessage msgId="alert_message" isVisible={messageIsVisible} type={messageType} fixed_position msgText={messageText} msgDetailedText={messageDetailedText} />

            <div className='datatable-container wrapper' data-testid="datatable-container wrapper">
                {firmwareDetailsTable && (
                    <eon-ui-data-table paging="true" zebra="false" selectable-rows="true" expandable-rows="false" id="firmware-details-table" page-sizes="10,20,50,100" initial-page-size="10" total-items={firmwareDetailsTable.length}>
                        <eon-ui-table-toolbar slot="table-toolbar" clear-input-icon="true">
                            <eon-ui-table-toolbar-selected slot="toolbar-selected">
                                <eon-ui-button slot="toolbar-selected-action-right" text={'datatable.button.updateSelected'.translate()} size="small" onClick={() => showModal('update-selected-modal')} />
                            </eon-ui-table-toolbar-selected>
                            <eon-ui-button slot="toolbar-default-action-left" text={'datatable.button.updateAll'.translate()} size="small" onClick={() => firmwareDetailsTable.length > 0 ? showModal('update-all-modal') : ''} />
                            {/*<eon-ui-button slot="toolbar-default-action-left" text={autoUpdateOn.current === false ? 'datatable.button.autoUpdateOff.label'.translate() : 'datatable.button.autoUpdateOn.label'.translate()} rank={autoUpdateOn.current === false ? 'primary' : 'secondary'} size="small" onClick={() => firmwareDetailsTable.length > 0 ? showModal('auto-update-modal') : ''} />*/}
                        </eon-ui-table-toolbar>
                        <eon-ui-table-head>
                            <eon-ui-table-head-row>
                                <eon-ui-table-head-cell sortable="true" sort-key="name" dangerouslySetInnerHTML={{ __html: 'datatable.table.header.name'.translate() }} />
                                <eon-ui-table-head-cell sortable="true" sort-key="model" dangerouslySetInnerHTML={{ __html: 'datatable.table.header.model'.translate() }} />
                                <eon-ui-table-head-cell sortable="true" sort-key="manufacturer" dangerouslySetInnerHTML={{ __html: 'datatable.table.header.manufacturer'.translate() }} />
                                <eon-ui-table-head-cell sortable="true" sort-key="version" dangerouslySetInnerHTML={{ __html: 'datatable.table.header.version'.translate() }} />
                                <eon-ui-table-head-cell sortable="true" sort-key="status" sort-order="asc">
                                    <eon-ui-icon name="more_small" no-space="false" size="normal" style={{ marginRight: 8 }} aria-label="more" alignment-x="center">
                                        <eon-ui-context-menu size="small" label={'datatable.filter.status.label'.translate()} scheme="white" icon-name="more" slot="context-menu" icon-alignment="left" class="eonui-position-left" visible={statusFilterActive} id="status-filter" >
                                            <eon-ui-context-menu-item value="" label={'datatable.filter.status.items.RESET'.translate()} active-icon-name="radio_checkbox_tick" />
                                            <eon-ui-context-menu-item value={statusBackend.AVAILABLE} label={'datatable.filter.status.items.AVAILABLE'.translate()} active-icon-name="radio_checkbox_tick" />
                                            <eon-ui-context-menu-item value={statusBackend.REQUESTED} label={'datatable.filter.status.items.REQUESTED'.translate()} active-icon-name="radio_checkbox_tick" />
                                            <eon-ui-context-menu-item value={statusBackend.FAILED} label={'datatable.filter.status.items.FAILED'.translate()} active-icon-name="radio_checkbox_tick" />
                                            <eon-ui-context-menu-item value={statusBackend.SECURITY_ALERT} label={'datatable.filter.status.items.SECURITY_ALERT'.translate()} active-icon-name="radio_checkbox_tick" />
                                            <eon-ui-context-menu-item value={statusBackend.UPTODATE} label={'datatable.filter.status.items.UPDATED'.translate()} active-icon-name="radio_checkbox_tick" />
                                        </eon-ui-context-menu>
                                    </eon-ui-icon>
                                    {'datatable.table.header.status'.translate()}
                                </eon-ui-table-head-cell>
                            </eon-ui-table-head-row>
                        </eon-ui-table-head>
                        <eon-ui-table-body style={{ height: '80px', minHeight: '80px' }}>
                            {firmwareDetailsTable.length > 0 && firmwareDetailsTable.map((firwareInfo) => {
                                return (
                                    <eon-ui-table-row key={firwareInfo.id} data-row-id={firwareInfo.id}>
                                        <eon-ui-table-cell value={firwareInfo.name}>{firwareInfo.name}</eon-ui-table-cell>
                                        <eon-ui-table-cell value={firwareInfo.model}>{firwareInfo.model}</eon-ui-table-cell>
                                        <eon-ui-table-cell value={firwareInfo.manufacturer}>{firwareInfo.manufacturer}</eon-ui-table-cell>
                                        <eon-ui-table-cell value={firwareInfo.currentVersion}>{firwareInfo.currentVersion}</eon-ui-table-cell>
                                        <eon-ui-table-cell value={`${displayStatus[firwareInfo.status]}`}>
                                            <eon-ui-tag-pill
                                                scheme={(displayStatus[firwareInfo.status] === displayStatus.REQUESTED ? statusColors.REQUESTED : displayStatus[firwareInfo.status] === displayStatus.UPTODATE ? statusColors.UPTODATE : statusColors.AVAILABLE)}
                                                icon={(displayStatus[firwareInfo.status] === displayStatus.FAILED ||
                                                    (displayStatus[firwareInfo.status] === displayStatus.SECURITY_ALERT ||
                                                        displayStatus[firwareInfo.status] === displayStatus.SECURITY_ALERT_HARDWARE ||
                                                        displayStatus[firwareInfo.status] === displayStatus.SECURITY_ALERT_SOFTWARE) ? 'alert' : 'bulletlist')}
                                                text={displayStatus[firwareInfo.status]}
                                                onClick={() => updateManually(firwareInfo.id)}
                                            />
                                        </eon-ui-table-cell>
                                    </eon-ui-table-row>
                                );
                            })}
                        </eon-ui-table-body>
                        {firmwareDetailsTable.length == 0 && (

                            <div className='datatable-caption'>
                                <eon-ui-text className='datatable-caption' text-style="copy" color="eon-black" dangerouslySetInnerHTML={{ __html: 'datatable.no-data'.translate() }} />
                            </div>


                        )}

                        {!!loader && (<LoadingComponent />)}
                    </eon-ui-data-table>
                )
                }

                {/* modals for toolbar */}
                <div>
                    <ModalComponent
                        modalId='update-all-modal'
                        copytext={'datatable.update-all-modal.copytext'.translate()}
                        headlineText={'datatable.update-all-modal.headlineText'.translate()}
                        closeBtnText={'datatable.update-all-modal.closeBtnText'.translate()}
                        confirmBtnId='confirm-update-all-btn'
                        confirmBtnText={'datatable.update-all-modal.confirmBtnText'.translate()}
                    />
                </div>
                <div>
                    <ModalComponent
                        modalId='update-selected-modal'
                        copytext={'datatable.update-selected-modal.copytext'.translate()}
                        headlineText={'datatable.update-selected-modal.headlineText'.translate()}
                        closeBtnText={'datatable.update-selected-modal.closeBtnText'.translate()}
                        confirmBtnId='confirm-update-selected-btn'
                        confirmBtnText={'datatable.update-selected-modal.confirmBtnText'.translate()}
                    />
                </div>
                <div>
                    <ModalComponent
                        modalId='auto-update-modal'
                        copytext={!autoUpdateOn ? 'datatable.auto-update-modal.autoUpdateOff.copytext'.translate() : 'datatable.auto-update-modal.autoUpdateOn.copytext'.translate()}
                        headlineText={!autoUpdateOn ? 'datatable.auto-update-modal.autoUpdateOff.headlineText'.translate() : 'datatable.auto-update-modal.autoUpdateOn.headlineText'.translate()}
                        closeBtnText={'datatable.auto-update-modal.closeBtnText'.translate()}
                        confirmBtnId='confirm-auto-update-btn'
                        confirmBtnText={'datatable.auto-update-modal.confirmBtnText'.translate()}
                    />
                </div>

                {/* modals for pills in datatable */}
                <ModalComponent
                    modalId='security-alert'
                    copytext={'datatable.security-alert.copytext'.translate()}
                    headlineText={'datatable.security-alert.headlineText'.translate()}
                    closeBtnText={'datatable.security-alert.closeBtnText'.translate()}
                    confirmBtnId='confirm-security-alert-btn'
                    confirmBtnText={'datatable.security-alert.confirmBtnText'.translate()}
                />
                <ModalComponent
                    modalId='security-alert-hardware'
                    copytext={'datatable.security-alert-hardware.copytext'.translate()}
                    headlineText={'datatable.security-alert-hardware.headlineText'.translate()}
                    closeBtnText={'datatable.security-alert-hardware.closeBtnText'.translate()}
                    confirmBtnId='confirm-security-alert-hardware-btn'
                    confirmBtnText={'datatable.security-alert-hardware.confirmBtnText'.translate()}
                />
                <ModalComponent
                    modalId='security-alert-software'
                    copytext={'datatable.security-alert-software.copytext'.translate()}
                    headlineText={'datatable.security-alert-software.headlineText'.translate()}
                    closeBtnText={'datatable.security-alert-software.closeBtnText'.translate()}
                    confirmBtnId='confirm-security-alert-software-btn'
                    confirmBtnText={'datatable.security-alert-software.confirmBtnText'.translate()}
                />
                <SingleBtnModalComponent
                    modalId='up-to-date-alert'
                    copytext={'datatable.up-to-date-alert.copytext'.translate()}
                    headlineText={'datatable.up-to-date-alert.headlineText'.translate()}
                    closeBtnText={'datatable.up-to-date-alert.closeBtnText'.translate()}
                />
                <ModalComponent
                    modalId='update-firmware-alert'
                    copytext={'datatable.update-firmware-alert.copytext'.translate()}
                    headlineText={'datatable.update-firmware-alert.headlineText'.translate()}
                    closeBtnText={'datatable.update-firmware-alert.closeBtnText'.translate()}
                    confirmBtnId='confirm-update-firmare-alert-btn'
                    confirmBtnText={'datatable.update-firmware-alert.confirmBtnText'.translate()}
                />
                <ModalComponent
                    modalId='update-failed-alert'
                    copytext={'datatable.update-failed-alert.copytext'.translate()}
                    headlineText={'datatable.update-failed-alert.headlineText'.translate()}
                    closeBtnText={'datatable.update-failed-alert.closeBtnText'.translate()}
                    confirmBtnId='confirm-update-failed-alert-btn'
                    confirmBtnText={'datatable.update-failed-alert.confirmBtnText'.translate()}
                />
                <SingleBtnModalComponent
                    modalId='update-in-progress-alert'
                    copytext={'datatable.update-in-progress-alert.copytext'.translate()}
                    headlineText={'datatable.update-in-progress-alert.headlineText'.translate()}
                    closeBtnText={'datatable.update-in-progress-alert.closeBtnText'.translate()}
                />

            </div>

        </>
    );
};

export default Datatable;