/* eslint-disable indent */
// load translation class
import Translate from '../translate.jsx';

/* create string.translate polyfill */
if (!String.prototype.translate) {
  String.prototype.translate = function (data) {
    console.log('translate', data);
    // init replace flag
    let replace = false;
    // init args
    let args = [];
    // check if data is object
    if (typeof data === 'object' && data !== null) {
      // set args to arg
      args = data;
      // activate replace
      replace = true;
    } else {
      // check arguments
      if (arguments.length > 0) {
        // process arguments
        for (let index = 0; index < arguments.length; index++) {
          // add argument to args
          args.push(arguments[index]);
        }
        // activate replace
        replace = true;
      }
    }

    // private function to replace arguments
    const _replace = function (match, index) {
      // check if index in args exists
      if (args[index]) {
        // return replacement
        return args[index];
      } else {
        // not found
        return '';
      }
    };

    // private function to get translation by path
    const _get = function (_path, _source) {
      // check if path has entries
      if (_path.length === 0) {
        // final node reached
        return _source;
      } else {
        // get firts key from path
        const _key = _path.shift();
        // check if exists in source
        if (_source[_key]) {
          // next iteration
          return _get(_path, _source[_key]);
        } else {
          // not found
          return false;
        }
      }
    };

    // check if translation exists
    if (Translate.translation) {
      // split string to translate
      const path = this.split('.');
      // try to get translation
      const translation = _get(path, Translate.translation);
      // check if found
      if (translation !== false) {
        // check if we have replacements
        if (replace === true) {
          // replace args in translation
          return translation.replace(/{([^}]+)}/g, _replace);
        } else {
          // return valid translation
          return translation;
        }
      } else {
        // translation not found
        return false;
      }
    }
  };
}

// export prototype
export default String;