import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import PropTypes from 'prop-types';

import { AuthContext } from '../../common-utility/authcontext';

import LoadingComponent from '../cpo-loading/cpoLoading';

import Navbar from '../cpo-navigation/navbar';
import Footer from '../cpo-footer/footer';

const Layout = ( { children } ) => {

    const { isAuthenticated, loading } = useContext(AuthContext);

    if (loading) {
        // c
        return <LoadingComponent />;
    }


    if (isAuthenticated) {
        return (
            <>
                <Navbar />
                {children || <Outlet />}
                <Footer />
            </>
        );
    } else {
        return <Navigate to='/' replace />;
    }

};


Layout.propTypes = {
    children: PropTypes.any,
};
Layout.defaultProps = {
    children: null,
};


export default Layout;