import React, { createContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';


import { loginAuthentication, getCiamUser, getCookie, setCookie, deleteCookie } from './index';

export const AuthContext = createContext({});

export const AuthProvider = ({
    children,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userAuthToken, setUserAuthToken] = useState(null);
    const [loggedUser, setLoggedUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const getToken = async () => {

        const cachedToken = getCookie('token');

        if (cachedToken === 'undefined' || !cachedToken || cachedToken === null || cachedToken == '') {
            console.log('no userAuthToken');
        } else {
            setUserAuthToken(cachedToken);
            console.log('userAuthToken ', cachedToken);
        }

        if (cachedToken) {
            setLoading(false);

            if (location.pathname == '/') {
                console.log('AuthProvider navigate dashboard', location.pathname);
                navigate('/dashboard');
            }
        } else {
            console.log('setLoading ', false);
            setLoading(false);
        }
    };

    useEffect(() => {
        getToken();
    }, [userAuthToken]);

    const getAuthenticationInfo = async () => {
        setLoading(true);
        console.log('getAuthenticationInfo called');
        try {
            if (userAuthToken === 'undefined' || !userAuthToken || userAuthToken === null) {
                const response = await loginAuthentication();
                console.log(response);
                if (!response.errors) {

                    const sessionTimeoutIn = response.expiresIn;
                    const currentTime = Date.now();
                    const currentTimeInString = currentTime.toString();
                    const sessionExpTimeInHr = (sessionTimeoutIn - currentTimeInString) / 1000;

                    if (response.idToken) {
                        // TODO: Secure for https
                        setCookie('token', response.idToken, Math.round(sessionExpTimeInHr), '/', '');
                    }

                    if (response.expiresIn) {
                        // TODO: Secure for https
                        setCookie('exp', response.expiresIn, Math.round(sessionExpTimeInHr), '/', '');
                    }

                    setUserAuthToken(response.idToken);
                } else {
                    // error 
                    navigate('/error_api', { replace: true });
                }
                setLoading(false);
            } else {
                setLoading(false);
                console.log('Successfully authenticated user!');
                console.log('AuthProvider navigate dashboard');
                navigate('/dashboard');
            }
        } catch (e) {
            console.error(e); 
            
            // error 
            navigate('/error_api', { replace: true });
        }
    };

    const getCiamUserInfo = async () => {
        setLoading(true);
        console.log('getCiamUserInfo called');
        try {
            if (userAuthToken && (loggedUser === 'undefined' || !loggedUser || loggedUser === null)) {
                const response = await getCiamUser(userAuthToken);

                setLoggedUser(response);
                setLoading(false);
            } else {
                setLoading(false);
                console.log('Successfully ciam user!');

            }
        } catch (e) {
            console.error(e);

            // error 
            navigate('/error_api', { replace: true });
        }
    };


    const logout = () => {
        deleteCookie('token');
        deleteCookie('exp');
        sessionStorage.clear();
    };

    // your context logic
    return (
        <AuthContext.Provider value={{ isAuthenticated: !!userAuthToken, userAuthToken, loggedUser, loading, getAuthenticationInfo, getCiamUserInfo, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.any,
};
AuthProvider.defaultProps = {
    children: null,
};

export const useAuth = () => React.useContext(AuthContext);