import React from 'react';
import PropTypes from 'prop-types';

const AlertMessage = (props) => {
    const { msgId, isVisible, type, fixed_position, msgText, msgDetailedText } = props;

    let scheme = 'bordeaux';
    let icon = 'alert';

    switch (type) {
        case 'alert':
            scheme = 'bordeaux';
            icon = 'alert';
            break;

        case 'warning':
            scheme = 'limeyellow';
            icon = 'alert';
            break;

        case 'success':
            scheme = 'turquoiseDark';
            icon = 'info';
            break;
    }

    return (
        <div>
            <eon-ui-error-message id={msgId} show-closing-x='true' is-visible={isVisible} scheme={scheme} icon-name={icon} fixed-position={fixed_position} className='alert-msg'>
                <eon-ui-headline text={msgText}
                    slot='headline'
                    size='h5'
                    margin={false}
                    disable-line-wrap />
                <eon-ui-text
                    size='h2'
                    text-style='copy'
                >
                    {msgDetailedText}
                </eon-ui-text>
            </eon-ui-error-message>
        </div>
    );
};
AlertMessage.propTypes = {
    msgId: PropTypes.string,
    isVisible: PropTypes.bool,
    type: PropTypes.string,
    fixed_position: PropTypes.bool,
    msgText: PropTypes.string,
    msgDetailedText: PropTypes.string,
};
AlertMessage.defaultProps = {
    msgId: '',
    isVisible: true,
    type: 'alert',
    fixed_position: true,
    msgText: '',
    msgDetailedText: '',
};
export default AlertMessage;