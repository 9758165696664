import React from 'react';
import { Outlet } from 'react-router-dom';

import PropTypes from 'prop-types';

import NavbarGuest from '../cpo-navigation/navbarGuest';
import Footer from '../cpo-footer/footer';

const LayoutGuest = ({ children }) => {

    return (
        <>
            <NavbarGuest />
            {children || <Outlet />}
            <Footer />
        </>
    );


};


LayoutGuest.propTypes = {
    children: PropTypes.any,
};
LayoutGuest.defaultProps = {
    children: null,
};


export default LayoutGuest;