import React from 'react';
import PropTypes from 'prop-types';

const SingleBtnModalComponent = (props) => {
    const { modalId, copytext,headlineText,closeBtnText } = props;

    return (
        <eon-ui-modal
            modal-id={modalId}
            copytext={copytext}
            headline-text={headlineText}
            close-button-text={closeBtnText}
            close-button-rank="primary"
            show-closing-x="true"
        />
    );
};
SingleBtnModalComponent.propTypes = {
    modalId: PropTypes.string,
    copytext: PropTypes.string,
    headlineText: PropTypes.string,
    closeBtnText: PropTypes.string,
};
SingleBtnModalComponent.defaultProps = {
    modalId: '',
    copytext: '',
    headlineText: '',
    closeBtnText: '',
};

export default SingleBtnModalComponent;