export default {
    STATUS_COLORS: {
        UPTODATE: 'turquoise',
        AVAILABLE: 'red',
        FAILED: 'red',
        REQUESTED: 'darkgrey',
    },
    STATUS_BACKEND: {
        UPTODATE: 'UPTODATE',
        AVAILABLE: 'AVAILABLE',
        FAILED: 'FAILED',
        REQUESTED: 'REQUESTED',
        SECURITY_ALERT:'SECURITY_ALERT',
        SECURITY_ALERT_HARDWARE:'SECURITY_ALERT_HARDWARE', // set manual by security notification
        SECURITY_ALERT_SOFTWARE:'SECURITY_ALERT_SOFTWARE', // set manual by security notification
    },
};