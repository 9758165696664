import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import polyfill for translation
/* eslint-disable no-unused-vars */
import Translate from './classes/polyfills/translate.jsx';
/* eslint-enable no-unused-vars */

// load translation library
import TranslateClass from './classes/translate.jsx';

window.env = {};
fetch('/config.json').then((res) => res.json()).then((data) => {
    Object.keys(data).forEach(dataKey => {
        window.env[dataKey] = data[dataKey];
    });

    // disable console log for production
    if (window.location.hostname !== 'localhost') {
        console.log = function no_console() { };
    }

    TranslateClass.load('en', 'global').then(() => {
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <App />,
        );
    }).catch((error) => {
        // display error
        console.error('error while loading translation');
        console.error(error);
    });


    reportWebVitals();
});
