import React, { useEffect, useContext } from 'react';


import { AuthContext } from '../../common-utility/authcontext';

import { getParameterByName, getCookie } from '../../common-utility/index';
/*
import LoadingComponent from '../cpo-loading/cpoLoading';

import Dashboard from '../dashboard/dashboard';
*/

/**
 * LoginAuth component
 * This component acts as a login authenticator before sending the user to the CPO portal
 * @author Mitul Kirti <mitul.kirti@hcl.com>
 * @component
 * @example
 *  <LoginAuth />
 */
const LoginAuth = () => {

    const { userAuthToken, getAuthenticationInfo } = useContext(AuthContext);
    const codeForAuthentication = getParameterByName('code');

    console.log('LoginAuth');

    useEffect(() => {

        // userAuthToken from useContext is not available on Mount, so i used direct access
        const cachedToken = getCookie('token');

        try {
            console.log('LoginAuth useEffect');
            if (cachedToken == '' || codeForAuthentication) {
                console.log('window.location.href URL', `${window.env.CIAM_LOGIN_URL}/services/oauth2/authorize/expid_602029?client_id=${encodeURIComponent(window.env.CIAM_CLIENT_ID)}&redirect_uri=${encodeURIComponent(window.env.CIAM_REDIRECT_URL)}&response_type=code&scope=openid`);
                if (cachedToken == '' && codeForAuthentication === null) {
                    console.log('codeForAuthentication inside if', codeForAuthentication);
                    window.location.href = `${window.env.CIAM_LOGIN_URL}/services/oauth2/authorize/expid_602029?client_id=${encodeURIComponent(window.env.CIAM_CLIENT_ID)}&redirect_uri=${encodeURIComponent(window.env.CIAM_REDIRECT_URL)}&response_type=code&scope=openid`;
                    console.log('window.location.href', window.location.href);
                } else {
                    console.log('codeForAuthentication', codeForAuthentication);
                    getAuthenticationInfo();
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    }, [userAuthToken]);

    return (
        <></>
    );
};

export default LoginAuth;
