
export function getParameterByName(parameterName) {
    let result = null;
    let tmp = [];
    window.location.search
        .substr(1)
        .split('&')
        .forEach((item) => {
            tmp = item.split('=');
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

// function to swap authentication code into a token 
export async function loginAuthentication() {
    return new Promise((resolve, reject) => {
        const regexToReplace = /%3D/g;
        const codeValue = getParameterByName('code');
        const updatedCode = codeValue ? codeValue.replace(regexToReplace, '=') : null;
        const headersList = {
            Accept: '*/*',
            'Content-Type': 'application/json',
        };

        const requestOptions = {
            method: 'POST',
            headers: headersList,
            body: JSON.stringify(updatedCode),
            redirect: 'follow',
        };
        fetch(
            `${window.env.API_URL_AUTH}/oauth2/tokens/swap/v1?identityProvider=CIAM`,
            requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result) {
                    /*  const sessionTimeoutIn = result.expiresIn;
                      const currentTime = Date.now();
                      const currentTimeInString = currentTime.toString();
                      const sessionExpTimeInHr = (sessionTimeoutIn - currentTimeInString) / 1000;
                      // document.cookie = `token=${result.idToken};Max-Age=${Math.round(sessionExpTimeInHr)};Path=${'/'}`;
                      setCookie('token', result.idToken, Math.round(sessionExpTimeInHr), '/', '');
                      // document.cookie = `exp=${result.expiresIn};Max-Age=${Math.round(sessionExpTimeInHr)};Path=${'/'};Secure`;
                      setCookie('exp', result.expiresIn, Math.round(sessionExpTimeInHr), '/', '');
  */
                    resolve(result);
                } else {
                    reject(JSON.stringify(result.errors[0]));
                    console.log('Not a valid user:', JSON.stringify(result.errors[0]));
                }
            })
            .catch((error) => {
                console.log('Something went wrong', error);
                reject(error);
            });
    });
}

// function to get ciam user
export async function getCiamUser(token) {

    return new Promise((resolve, reject) => {

        const headersList = {
            Authorization: `Bearer ${token}`,
            Accept: '*/*',
            'Content-Type': 'application/json',
        };

        const requestOptions = {
            method: 'GET',
            headers: headersList,
        };
        fetch(
            `${window.env.API_URL_CUSTOMER_DATA}/ciamusers/v1`,
            requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result) {
                    resolve(result);
                } else {
                    reject(JSON.stringify(result.errors[0]));
                    console.log('Error:', JSON.stringify(result.errors[0]));
                }
            })
            .catch((error) => {
                console.log('Something went wrong', error);
                reject(error);
            });
    });
}

export function setCookie(name, value, expires, path = '/', secure = 'Secure') {
    console.log('setCookie', name, value, expires, path);
    document.cookie = name + '=' + encodeURIComponent(value) + '; Max-Age=' + expires + '; path=' + path + ' ' + secure;
}

export function getCookie(name) {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
}

export function deleteCookie(name, path) {
    setCookie(name, '', -1, path, '');
}

export default { getParameterByName, loginAuthentication, getCiamUser, setCookie, getCookie, deleteCookie };