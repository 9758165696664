/* eslint-disable indent */
// create translate class
export default class Translate {
	// static storage for loaded translation
	static translation = {};

	// function to load translation
	static load(language, file) {
		// return new promise
		return new Promise((resolve, reject) => {
			// create filename
			const fileName = '/i18n/' + language + '/' + file + '.json?' + Date.now();

			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			};

			fetch(fileName, requestOptions)
				.then(async (response) => {

					let result = await response.json();

					// merge result
					Translate.translation = { ...Translate.translation, ...result };

					// resolve
					resolve();
				}).catch((error) => {
					console.error('unable to load translation');
					console.error(error);
					// reject
					reject();
				});

		});
	}
}