import React, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../common-utility/authcontext';

import Datatable from '../datatable/datatable';
import './dashboard.scss';
import LoadingComponent from '../cpo-loading/cpoLoading';
import AlertMessage from '../cpo-message/alertMessage';

/**
 * Dashboard component
 * This component acts as the landing page for CPO portal
 * @author Mitul Kirti <mitul.kirti@hcl.com>
 * @component
 * @example
 *  <Dashboard />
 */

const Dashboard = () => {

    const { userAuthToken } = useContext(AuthContext);

    const [loader, setLoader] = useState(false);

    console.log('Dashboard');
    useEffect(() => {
        console.log('Dashboard useEffect');
        if (userAuthToken === 'undefined' || !userAuthToken || userAuthToken === null) {
            console.log('userAuthToken inside useeffect', userAuthToken);
            setLoader(true);
        } else {
            setLoader(false);
        }
    }, [userAuthToken]);

    /**
     * Function call when user clicks on confirm button on modal
     */

    return (
        <React.Fragment>
            {(userAuthToken === 'undefined' || !userAuthToken || userAuthToken === null) ? (
                <div className='error-container'>
                    <AlertMessage isVisible={true} scheme="red" icon="alert" msgText="Error" msgDetailedText="Login authentication failed" />
                </div>
            ) :
                <React.Fragment>
                    <div className='headline-container'>
                        <div className='wrapper'>
                            <eon-ui-headline text={'dashboard.headline'.translate()} alignment="right" margin="false" size="h1" />
                        </div>
                    </div>
                    {!loader && (<Datatable />)}
                    {!!loader && (<LoadingComponent />)}
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default Dashboard;