import React from 'react';
import PropTypes from 'prop-types';

const ModalComponent = (props) => {
    const { modalId, copytext,headlineText,closeBtnText,confirmBtnId,confirmBtnText } = props;

    return (
        <eon-ui-modal
            modal-id={modalId}
            copytext={copytext}
            headline-text={headlineText}
            close-button-text={closeBtnText}
            close-button-rank="secondary"
            show-closing-x="true"
        >
            <eon-ui-button
                class="hydrated eon-btn-text-and-icon"
                text={confirmBtnText}
                input-type="button"
                full-width-on-mobile="true"
                slot="button-right"
                id={confirmBtnId}
            />
        </eon-ui-modal>
    );
};
ModalComponent.propTypes = {
    modalId: PropTypes.string,
    copytext: PropTypes.string,
    headlineText: PropTypes.string,
    closeBtnText: PropTypes.string,
    confirmBtnId: PropTypes.string,
    confirmBtnText:PropTypes.string,
};
ModalComponent.defaultProps = {
    modalId: '',
    copytext: '',
    headlineText: '',
    closeBtnText: '',
    confirmBtnId: '',
    confirmBtnText:'',
};

export default ModalComponent;