import React, { useEffect } from 'react';

import './error.scss';

import PropTypes from 'prop-types';

/**
 * Error component
 * This component acts as the landing page for CPO portal
 * @author Michael Deumlich <m.deumlich@enport.de>
 * @component
 * @example
 *  <Error />
 */

const Error = (props) => {

    const { type } = props;

    console.log('Error');
    useEffect(() => {
        console.log('Error useEffect');

    }, []);

    /**
     * Function call when user clicks on confirm button on modal
     */

    let error_headline = '';
    let error_text = '';
    let error_button = '';



    switch (type) {
        case 'expired':
            error_headline = 'Session expired';
            error_text = 'Your session has expired. Please log in again.';
            error_button = 'Back to login';
            break;
        case 'notfound':
            error_headline = 'Page not found';
            error_text = 'This page may no longer be available. It could have have been moved, deleted or it doesn\'t exist.';
            error_button = 'Back to login';
            break;
        case 'api':
            error_headline = 'Your request could not be completed';
            error_text = 'We have encountered a technical issue and could not complete your request. Please reload the page and try again.';
            error_button = 'Back to login';
            break;
    }

    return (
        <>
            <div className='headline-container'>
                <div className='wrapper'>
                    <eon-ui-headline text={error_headline} alignment="right" margin="false" size="h1" />
                </div>
            </div>
            <div className='wrapper'>
                <div className=''>
                    <eon-ui-box scheme="white">
                        <eon-ui-text text-style="copy" color="eon-black">
                            {error_text}
                        </eon-ui-text>
                        <br />
                        <eon-ui-button text={error_button} rank='primary' size="small" onClick={() => window.location.href = window.env.CIAM_REDIRECT_URL} />
                    </eon-ui-box>
                </div>
            </div>
        </>
    );
};


Error.propTypes = {
    type: PropTypes.string,

};
Error.defaultProps = {
    type: '',
};

export default Error;