import React from 'react';

/**
 * Footer component
 * This is the Footer component for CPO Portal.
 * @author Mitul Kirti <mitul.kirti@hcl.com>
 * @component
 * @example
 *  <Footer />
 */

const Footer = () => {
    return (
        <div>
            <eon-ui-footer-bar copyright={'©'+new Date().getFullYear()+' E.ON Drive GmbH'}>
                {/* <eon-ui-link hide-icon="true" slot="socialmedia" target="_blank" size="small" text='PKI' href='secondLink'/>
                <eon-ui-link hide-icon="true" slot="socialmedia" target="_blank" size="small" text='Contact' href='thirdLink' />
                <eon-ui-link hide-icon="true" slot="socialmedia" target="_blank" size="small" text='Terms of Use' href='thirdLink' /> */}
                <eon-ui-link hide-icon="true" slot="socialmedia" target="_blank" size="small" text=' Privacy Policy' href='thirdLink' />
                <eon-ui-link hide-icon="true" slot="socialmedia" target="_blank" size="small" text='Imprint' href='thirdLink' />
                <eon-ui-image slot="logo" src="/EON_Drive_Logo.svg" alt="E.ON Logo"/>
            </eon-ui-footer-bar>
        </div>
    );
};
export default Footer;