import React, { useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../common-utility/index';
// import LoadingComponent from '../cpo-loading/cpoLoading';


import { AuthContext } from '../../common-utility/authcontext';

import './navbar.scss';

import CountDown from '../cpo-countdown/countdown';

const Navbar = () => {

    const navigate = useNavigate();

    const { isAuthenticated, loggedUser, loading, getCiamUserInfo, logout } = useContext(AuthContext);


    const exp = getCookie('exp') || null;


    const isMounted = useRef(false);

    useEffect(() => {

        if (!isMounted.current) {

            if (exp) {
                setTimeout(function () {
                    navigate('/error_expired');
                }, exp - new Date().getTime());
            }

            document.getElementById('navbarLogoutBtn').addEventListener('navigationMainIconLinkClick', () => {

                console.log('Logout btn clicked');
                logout();

                console.log('User has logged out!', document.cookie);
            });


            if (isAuthenticated && loggedUser === null) {
                getCiamUserInfo();
            }

            isMounted.current = true;
        }
        return () => {
            isMounted.current = false;
        };
    }, [document.location.href]);

    const reloadPage = () => {
        // window.location.reload();
        window.location.href = window.env.CIAM_REDIRECT_URL;
    };

    if (!exp) {
        navigate('/error_expired');
    }

    return (
        <>
            <div className="color-separator" data-testid="color-separator">
                <eon-ui-color-separator />
            </div>
            <div className='wrapper' data-testid="wrapper">
                <eon-ui-navigation sticky="false">
                    <eon-ui-navigation-main slot="main">
                        <eon-ui-navigation-column content-alignment="right" content-alignment-mobile="left" grow="false" grow-mobile="false">
                            {loading &&
                                <>
                                </>
                            }
                            {isAuthenticated &&
                                <>
                                    <span className="counter-btn" data-testid="navbar-counter">
                                        Hi {loggedUser && loggedUser.firstName}
                                        <CountDown targetDate={exp ? +exp : null} />
                                    </span>
                                </>
                            }

                            <eon-ui-navigation-main-icon-link icon="user">
                                <eon-ui-context-menu label="User options" scheme="white" size="normal" slot="context-menu" icon-name="user" menu-offset-x="4" class="eonui-position-right hydrated" icon-alignment="right" visible="false">
                                    <eon-ui-context-menu-item value="value-1" label="Change password" class="hydrated" onClick={() => window.location.href = window.env.CIAM_CHANGE_PWD_URL } />
                                </eon-ui-context-menu>
                            </eon-ui-navigation-main-icon-link>

                            <eon-ui-navigation-main-icon-link id="navbarLogoutBtn" icon="logout" href={`${window.env.CIAM_LOGOUT_URL}`} />
                            <eon-ui-navigation-logo image-src="/EON_Drive_Logo.svg" description="E.ON Logo" onClick={() => reloadPage()} />

                        </eon-ui-navigation-column>


                    </eon-ui-navigation-main>


                </eon-ui-navigation>
            </div>
        </>

    );
};

export default Navbar;